import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { wrap: "" } },
    [
      _c(
        VNavigationDrawer,
        {
          style: _vm.panelWidthStyle,
          attrs: { absolute: "", permanent: "", right: "" },
        },
        [
          _c(
            VList,
            { staticClass: "pa-1" },
            [
              _c(
                VListItem,
                [
                  _c(VListItemAvatar, [
                    _vm.value._type == "StickyTextElement"
                      ? _c("img", { attrs: { src: _vm.value.imgSrc } })
                      : _c("img", { attrs: { src: _vm.imgSrc } }),
                  ]),
                  _c(
                    VCol,
                    [
                      _c(VListItemTitle, { staticClass: "headline" }, [
                        _vm._v(_vm._s(_vm.value._type)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.closePanel()
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { color: "grey lighten-1" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VList,
            { staticClass: "pt-0", attrs: { dense: "", flat: "" } },
            [
              _c(VDivider),
              _c(
                VCard,
                { attrs: { outlined: "" } },
                [
                  _vm.value._type != "StickyTextElement"
                    ? _c(
                        VCardText,
                        [
                          _vm.isShowError
                            ? _c("div", { staticStyle: { color: "skyblue" } }, [
                                _vm._v("Notice: " + _vm._s(_vm.showError)),
                              ])
                            : _vm._e(),
                          _c(VTextField, {
                            attrs: {
                              disabled: _vm.isReadOnly,
                              label: "Name",
                              autofocus: "",
                            },
                            model: {
                              value: _vm.value.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "name", $$v)
                              },
                              expression: "value.name",
                            },
                          }),
                          _c(VTextarea, {
                            attrs: {
                              label: "Description",
                              disabled: _vm.isReadOnly,
                              "auto-grow": "",
                            },
                            model: {
                              value: _vm.value.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "description", $$v)
                              },
                              expression: "value.description",
                            },
                          }),
                          _vm.value._type == "UserStory"
                            ? _c(VTextField, {
                                attrs: {
                                  disabled: _vm.isReadOnly,
                                  label: "As",
                                },
                                model: {
                                  value: _vm.value.as,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.value, "as", $$v)
                                  },
                                  expression: "value.as",
                                },
                              })
                            : _vm._e(),
                          _vm.value._type == "UserStory"
                            ? _c(VTextField, {
                                attrs: {
                                  disabled: _vm.isReadOnly,
                                  label: "I Want",
                                },
                                model: {
                                  value: _vm.value.iWant,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.value, "iWant", $$v)
                                  },
                                  expression: "value.iWant",
                                },
                              })
                            : _vm._e(),
                          _vm.value._type == "UserStory"
                            ? _c(VTextField, {
                                attrs: {
                                  disabled: _vm.isReadOnly,
                                  label: "So that",
                                },
                                model: {
                                  value: _vm.value.soThat,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.value, "soThat", $$v)
                                  },
                                  expression: "value.soThat",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "my-5" },
                            [
                              _c(VLabel, [_vm._v("Color")]),
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                _vm._l(_vm.colorList, function (color, idx) {
                                  return _c(
                                    "div",
                                    { key: idx },
                                    [
                                      _vm.value.color == color
                                        ? _c(
                                            VBtn,
                                            {
                                              staticClass: "my-2 mx-2",
                                              attrs: {
                                                fab: "",
                                                small: "",
                                                depressed: "",
                                                color: color,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.changeColor(color)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VIcon,
                                                { attrs: { dark: "" } },
                                                [_vm._v("mdi-check")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(VBtn, {
                                            staticClass: "my-2 mx-2",
                                            attrs: {
                                              fab: "",
                                              small: "",
                                              depressed: "",
                                              color: color,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeColor(color)
                                              },
                                            },
                                          }),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.value._type == "StickyTextElement"
                    ? _c(
                        VCardText,
                        [
                          _vm.isShowError
                            ? _c("div", { staticStyle: { color: "skyblue" } }, [
                                _vm._v("Notice: " + _vm._s(_vm.showError)),
                              ])
                            : _vm._e(),
                          _c(VTextField, {
                            attrs: {
                              disabled: _vm.isReadOnly,
                              label: "Name",
                              autofocus: "",
                            },
                            model: {
                              value: _vm.value.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "name", $$v)
                              },
                              expression: "value.name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.value._type == "UserStoryMapLineElement"
                    ? _c(
                        VCardText,
                        [
                          _c(VLabel, [_vm._v("Color")]),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            _vm._l(_vm.colorList, function (color, idx) {
                              return _c(
                                "div",
                                { key: idx },
                                [
                                  _vm.value.color == color &&
                                  (_vm.value.color == "#F1A746" ||
                                    _vm.value.color == "#5099F7" ||
                                    _vm.value.color == "#BB94BF" ||
                                    _vm.value.color == "#F8D454" ||
                                    _vm.value.color == "#ED73B6")
                                    ? _c(
                                        VBtn,
                                        {
                                          staticClass: "my-2 mx-2",
                                          attrs: {
                                            fab: "",
                                            "x-small": "",
                                            depressed: "",
                                            color: color,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeColor(color)
                                            },
                                          },
                                        },
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-check-bold"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm.value.color == color &&
                                      (_vm.value.color == "#000000" ||
                                        _vm.value.color == "#9E9E9E" ||
                                        _vm.value.color == "#5FC08B" ||
                                        _vm.value.color == "#8E24AA")
                                    ? _c(
                                        VBtn,
                                        {
                                          staticClass: "my-2 mx-2",
                                          attrs: {
                                            fab: "",
                                            "x-small": "",
                                            depressed: "",
                                            dark: "",
                                            color: color,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeColor(color)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            VIcon,
                                            { attrs: { dark: "" } },
                                            [_vm._v("mdi-check-bold")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(VBtn, {
                                        staticClass: "my-2 mx-2",
                                        attrs: {
                                          fab: "",
                                          "x-small": "",
                                          depressed: "",
                                          color: color,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeColor(color)
                                          },
                                        },
                                      }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "my-5" },
                            [
                              _c(VLabel, [_vm._v("Size")]),
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c(VBtn, {
                                    staticClass: "mx-1 my-2",
                                    staticStyle: {
                                      width: "100px",
                                      height: "5px",
                                    },
                                    attrs: {
                                      depressed: "",
                                      tile: "",
                                      color: "#212121",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.resizedEdge(5)
                                      },
                                    },
                                  }),
                                  _c(VBtn, {
                                    staticClass: "mx-1 my-2",
                                    staticStyle: {
                                      width: "100px",
                                      height: "10px",
                                    },
                                    attrs: {
                                      depressed: "",
                                      tile: "",
                                      color: "#212121",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.resizedEdge(10)
                                      },
                                    },
                                  }),
                                  _c(VBtn, {
                                    staticClass: "mx-1 my-2",
                                    staticStyle: {
                                      width: "100px",
                                      height: "15px",
                                    },
                                    attrs: {
                                      depressed: "",
                                      tile: "",
                                      color: "#212121",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.resizedEdge(15)
                                      },
                                    },
                                  }),
                                  _c(VBtn, {
                                    staticClass: "mx-1 my-2",
                                    staticStyle: {
                                      width: "100px",
                                      height: "20px",
                                    },
                                    attrs: {
                                      depressed: "",
                                      tile: "",
                                      color: "#212121",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.resizedEdge(20)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "my-5" },
                            [
                              _c(VLabel, [_vm._v("Line Style")]),
                              _c(
                                VRow,
                                { staticClass: "ma-0 pa-0" },
                                [
                                  _c(VBtn, {
                                    staticClass: "mx-1 my-2",
                                    staticStyle: {
                                      width: "100px",
                                      height: "10px",
                                      "border-bottom": "10px solid black",
                                    },
                                    attrs: { depressed: "", tile: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeDashStyle("")
                                      },
                                    },
                                  }),
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "mx-1 my-2",
                                      staticStyle: {
                                        width: "100px",
                                        height: "10px",
                                      },
                                      attrs: { depressed: "", tile: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeDashStyle(".")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          attrs: { width: "100", height: "10" },
                                        },
                                        [
                                          _c("rect", {
                                            attrs: {
                                              x: "0",
                                              y: "0",
                                              width: "8",
                                              height: "10",
                                              fill: "black",
                                            },
                                          }),
                                          _c("rect", {
                                            attrs: {
                                              x: "14",
                                              y: "0",
                                              width: "8",
                                              height: "10",
                                              fill: "black",
                                            },
                                          }),
                                          _c("rect", {
                                            attrs: {
                                              x: "28",
                                              y: "0",
                                              width: "8",
                                              height: "10",
                                              fill: "black",
                                            },
                                          }),
                                          _c("rect", {
                                            attrs: {
                                              x: "42",
                                              y: "0",
                                              width: "8",
                                              height: "10",
                                              fill: "black",
                                            },
                                          }),
                                          _c("rect", {
                                            attrs: {
                                              x: "56",
                                              y: "0",
                                              width: "8",
                                              height: "10",
                                              fill: "black",
                                            },
                                          }),
                                          _c("rect", {
                                            attrs: {
                                              x: "70",
                                              y: "0",
                                              width: "8",
                                              height: "10",
                                              fill: "black",
                                            },
                                          }),
                                          _c("rect", {
                                            attrs: {
                                              x: "84",
                                              y: "0",
                                              width: "8",
                                              height: "10",
                                              fill: "black",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(VBtn, {
                                    staticClass: "mx-1 my-2",
                                    staticStyle: {
                                      width: "100px",
                                      height: "10px",
                                      "border-bottom": "10px dashed black",
                                    },
                                    attrs: { depressed: "", tile: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeDashStyle("- ")
                                      },
                                    },
                                  }),
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "mx-1 my-2",
                                      staticStyle: {
                                        width: "100px",
                                        height: "10px",
                                      },
                                      attrs: { depressed: "", tile: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeDashStyle("--")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          attrs: { width: "100", height: "10" },
                                        },
                                        [
                                          _c("line", {
                                            attrs: {
                                              x1: "0",
                                              y1: "5",
                                              x2: "30",
                                              y2: "5",
                                              stroke: "black",
                                              "stroke-width": "10",
                                            },
                                          }),
                                          _c("line", {
                                            attrs: {
                                              x1: "35",
                                              y1: "5",
                                              x2: "65",
                                              y2: "5",
                                              stroke: "black",
                                              "stroke-width": "10",
                                            },
                                          }),
                                          _c("line", {
                                            attrs: {
                                              x1: "70",
                                              y1: "5",
                                              x2: "100",
                                              y2: "5",
                                              stroke: "black",
                                              "stroke-width": "10",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }